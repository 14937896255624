"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sum = void 0;
var units_1 = require("./lib/units");
var parse_1 = require("./parse");
/**
 * Sum durations.
 *
 * @example
 * sum({ days: 1 }, { days: 2, hours: 12 })
 * { days: 3, hours: 12 }
 */
var sum = function () {
    var durations = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        durations[_i] = arguments[_i];
    }
    var output = __assign({}, units_1.ZERO);
    durations.map(parse_1.parse).forEach(function (duration) {
        units_1.UNITS.forEach(function (key) {
            output[key] += duration[key];
        });
    });
    return output;
};
exports.sum = sum;
