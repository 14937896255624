"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNITS_META = exports.UNITS = exports.UNITS_META_MAP = exports.UNITS_META_MAP_LITERAL = exports.ZERO = void 0;
// Durations defined by ISO 31-1
var MILLISECONDS_IN_A_SECOND = 1000;
var MILLISECONDS_IN_A_MINUTE = MILLISECONDS_IN_A_SECOND * 60;
var MILLISECONDS_IN_AN_HOUR = MILLISECONDS_IN_A_MINUTE * 60;
var MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_AN_HOUR * 24;
// Durations defined by common sense
var MILLISECONDS_IN_A_WEEK = MILLISECONDS_IN_A_DAY * 7;
var MILLISECONDS_IN_A_YEAR = MILLISECONDS_IN_A_DAY * 365;
var MILLISECONDS_IN_A_MONTH = MILLISECONDS_IN_A_YEAR / 12;
exports.ZERO = Object.freeze({
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
});
exports.UNITS_META_MAP_LITERAL = {
    years: {
        milliseconds: MILLISECONDS_IN_A_YEAR,
        months: 12,
        dateGetter: function (date) { return date.getFullYear(); },
        ISOCharacter: 'Y',
        ISOPrecision: 'period',
    },
    months: {
        milliseconds: MILLISECONDS_IN_A_MONTH,
        months: 1,
        dateGetter: function (date) { return date.getMonth(); },
        ISOCharacter: 'M',
        ISOPrecision: 'period',
    },
    weeks: {
        milliseconds: MILLISECONDS_IN_A_WEEK,
        dateGetter: function () { return 0; },
        ISOCharacter: 'W',
        ISOPrecision: 'period',
        stringifyConvertTo: 'days',
    },
    days: {
        milliseconds: MILLISECONDS_IN_A_DAY,
        dateGetter: function (date) { return date.getDate(); },
        ISOCharacter: 'D',
        ISOPrecision: 'period',
    },
    hours: {
        milliseconds: MILLISECONDS_IN_AN_HOUR,
        dateGetter: function (date) { return date.getHours(); },
        ISOCharacter: 'H',
        ISOPrecision: 'time',
    },
    minutes: {
        milliseconds: MILLISECONDS_IN_A_MINUTE,
        dateGetter: function (date) { return date.getMinutes(); },
        ISOCharacter: 'M',
        ISOPrecision: 'time',
    },
    seconds: {
        milliseconds: MILLISECONDS_IN_A_SECOND,
        dateGetter: function (date) { return date.getSeconds(); },
        ISOCharacter: 'S',
        ISOPrecision: 'time',
    },
    milliseconds: {
        milliseconds: 1,
        dateGetter: function (date) { return date.getMilliseconds(); },
        stringifyConvertTo: 'seconds',
    },
};
// Re-export with a defined signature to allow for destructuring of optional
// properties.
//
// The original `UNITS_MAP_LITERAL` is useful as TypeScript does not need a null
// check when accessing an optional property that it knows is defined for the
// specified unit (e.g. `UNITS_MAP_LITERAL.years.months`), whereas `UNITS_MAP`
// needs the null check.
exports.UNITS_META_MAP = exports.UNITS_META_MAP_LITERAL;
/**
 * All the keys of the `Duration` type, ordered from largest
 * to smallest.
 */
exports.UNITS = Object.freeze([
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds',
]);
exports.UNITS_META = Object.freeze(exports.UNITS.map(function (unit) { return (__assign(__assign({}, exports.UNITS_META_MAP[unit]), { unit: unit })); }));
